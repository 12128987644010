<template>
  <v-footer
    id="page-footer"
    padless
    style="background: #000; border-top: 1px solid hsla(0,0%,100%,.2);"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="4" class="order-md-last">
          <v-btn text href="/privacy">Privacy Policy</v-btn>
          <v-btn text href="/terms">Terms &amp; Conditions</v-btn>
        </v-col>
        <v-col cols="12" md="8">
          <v-card
            style="background: transparent"
            class="flex"
            flat
            tile
          >
            <v-card-text class="py-2">
              <p class="ml-4">
                <span class="ml-n8">&copy; &#x2117;</span> Lava Productions, LLC dba Lava for Good&trade; Podcasts. Lava, Lava for Good&trade;,
                and the Lava logo are trademarks of Lava Media, LLC.</p>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  // Components
  export default {
    name: 'PageFooter',
  }
</script>

<style lang="sass">
#page-footer .v-btn
  text-transform: none
#page-footer a
  text-decoration: none
.container
  max-width: 1200px
</style>

